<script>
import {
 getVariants, hasProductMaxQuantity, isProductItemValid, isProductValueDisabled,
} from '@/@core/utils/utils'

export default {
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    isCarton: {
      type: Boolean,
      required: true,
    },
    // This variable is used in Order Details page
    // By clicking modify the button will be enabled
    isModifyEnabledBrand: {
      type: Boolean,
      default() {
        return false
      },
    },
    // This variable is used in cart and product detail page
    // to enable the inputs for free size products
    isEditEnabled: {
      type: Boolean,
      default() {
        return false
      },
    },
    // For inactive collections
    disabledProduct: {
      type: Boolean,
      default() {
        return false
      },
    },
    // Min quantity per size
    minQtyPerSize: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  data() {
    const COLOR = 'color'
    const headings = [{
      key: COLOR,
      label: 'Color',
    }]
    return {
      prouctItems: null,
      headings,
      variants: [],
      COLOR,
      isProductItemValid,
      isProductValueDisabled,
      hasProductMaxQuantity,
    }
  },
  computed: {
    isDisabled() {
      return this.isCarton || this.isCancelled || this.isGuest || this.disabledProduct || (!this.isEditEnabled && !this.isModifyEnabledBrand)
    },
    // This is used to show max quantity for free size products
    canShowMaxQty() {
      return !this.isCarton && (this.isModifyEnabledBrand || this.isEditEnabled)
    },
  },
  mounted() {
    this.prouctItems = JSON.parse(JSON.stringify(this.items))
    this.initializevariants(this.items)
  },
  methods: {
    initializevariants(productItems) {
      const { headings, variants } = getVariants(productItems, this.headings, this.COLOR)
      if (productItems.length) {
        this.headings = headings
        this.variants = variants
        this.$emit('update-variants', this.variants)
      }
    },
  },
  emits: ['update-variants'],
}
</script>
