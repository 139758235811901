<template>
  <div class="product-checkout position-relative">
    <!-- Checkout Options -->
    <b-row
      v-if="showTotal"
      class="mb-2"
      :class="{ 'cart-actions': !isPopup }"
    >
      <b-col
        v-if="!isPopup"
        class="col-12 col-sm-6 mb-1 mb-sm-0"
      >
        <b-button
          v-b-tooltip.hover.bottom.v-primary
          variant="outline-secondary-black"
          class="ml-1 float-left px-1"
          title="Back to Collection"
          link
          :to="{
            name: 'collection/products',
            params: { collectionId: collectionId },
          }"
          @click="gotoCollection"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            size="16"
          />
        </b-button>
        <b-button
          variant="secondary-black"
          class="ml-1 float-left"
          @click="showBuyingSummary"
        >
          <feather-icon
            icon="FileTextIcon"
            size="16"
          />
          Buying Summary
        </b-button>
      </b-col>
      <b-col
        class="col-12 d-flex justify-content-sm-end"
        :class="{ 'col-sm-6': !isPopup }"
      >
        <b-button
          v-b-modal.import_cart_modal
          variant="outline-secondary-black"
          class="mr-1"
        >
          <import-icon class="align-top" />
          Import
        </b-button>
        <b-button
          class="border-10"
          variant="outline-secondary-black"
          @click="onClickExportCart"
        >
          <export-icon class="align-top" />
          Export
        </b-button>
      </b-col>
    </b-row>
    <div :class="{ 'checkout-options': !!showTotal, 'mt-1': isPopup }">
      <b-row v-if="showTotal">
        <b-col
          cols="12"
          md="6"
        >
          <!-- Primary shipping Address in cart -->
          <b-card
            v-if="profileData"
            class="h-100 position-relative"
          >
            <div class="field-heading mb-50 color-dark-blue">
              <feather-icon
                icon="MessageSquareIcon"
                size="22"
              />
              <h4
                class="
                  mb-0
                  ml-75
                  font-weight-bolder
                  color-neutral-black
                  d-inline-block
                "
              >
                Notes
              </h4>
              <b-button
                v-if="isEditNotes && isRetailer"
                class="ml-1"
                size="sm"
                :disabled="isSavingNotes"
                variant="custom-primary"
                @click="onUpdateNotes"
              >
                {{ isSavingNotes ? 'Saving' : 'Save' }}
              </b-button>
              <span
                v-if="!isEditNotes"
                class="color-actions-text-grey ml-50"
              >
                <feather-icon
                  icon="EditIcon"
                  size="15"
                  class="edit-icon cursor-pointer"
                  @click="isEditNotes = true"
                />
              </span>
            </div>
            <div>
              <b-form-textarea
                v-if="isEditNotes"
                v-model="notes"
                placeholder="Enter Notes"
              />
              <span
                v-else
                class="color-actions-text-grey"
              >{{ notes }}</span>
            </div>
            <div
              class="d-flex align-items-center mt-1 color-neutral-black"
              :class="
                profileData.shippingAddress.addressLine1
                  ? 'mb-2'
                  : 'shipping-address-btn-margin'
              "
            >
              <feather-icon
                icon="TruckIcon"
                size="20"
              />
              <h4
                class="mb-0 ml-75 w-100 font-weight-bolder color-neutral-black"
              >
                Shipping Address
              </h4>
            </div>
            <div
              v-if="profileData.shippingAddress.addressLine1"
              class="mb-5"
            >
              <div class="pb-1">
                <address-detail :address="profileData.shippingAddress" />
              </div>
              <div>
                <!-- popup for showing address list -->
                <b-modal
                  id="modal-select"
                  size="lg"
                  centered
                  title="Select Address"
                  hide-footer
                >
                  <address-list
                    class="mb-1"
                    :address-type="ADDRESSES_TYPE.SHIPPING"
                    :is-popup="true"
                    :address="shippingAddress"
                    :commons="countryData"
                    :add-or-update-address="addOrUpdateAddress"
                    :set-as-primary-address="setAsPrimaryAddress"
                  />
                </b-modal>
              </div>
            </div>
            <div
              v-if="!isPopup"
              class="position-bottom mb-1"
            >
              <div
                v-if="profileData.shippingAddress.addressLine1"
                class="text-center my-1 mx-2"
              >
                <b-button
                  variant="secondary-black"
                  block
                  @click="getUserProfile(true)"
                >
                  Select Another Address
                </b-button>
              </div>
              <div
                v-if="!profileData.shippingAddress.addressLine1"
                class="ml-2"
              >
                Please add
                {{
                  !profileData.billingAddress.addressLine1
                    ? 'a Billing and'
                    : ''
                }}
                a Shipping Address.
              </div>
              <div
                v-if="!profileData.billingAddress.addressLine1"
                class="text-center my-1 mx-2"
              >
                <b-button
                  variant="outline-secondary-black"
                  block
                  @click="$bvModal.show('modal-add-billing')"
                >
                  Add Billing Address
                </b-button>
                <b-modal
                  id="modal-add-billing"
                  size="lg"
                  centered
                  title="Add Billing Address"
                  hide-footer
                  @show="getUserProfile(false)"
                >
                  <address-list
                    class="pb-2"
                    :address-type="ADDRESSES_TYPE.BILLING"
                    :commons="countryData"
                    :address="billingAddress"
                    :add-or-update-address="addOrUpdateAddress"
                  />
                </b-modal>
              </div>
              <div
                v-if="!profileData.shippingAddress.addressLine1"
                class="text-center my-1 mx-2"
              >
                <b-button
                  variant="outline-secondary-black"
                  block
                  @click="$bvModal.show('modal-add-shipping')"
                >
                  Add Shipping Address
                </b-button>
                <b-modal
                  id="modal-add-shipping"
                  size="lg"
                  centered
                  title="Add Shipping Address"
                  hide-footer
                  @show="getUserProfile(false)"
                >
                  <address-list
                    class="pb-2"
                    :address-type="ADDRESSES_TYPE.SHIPPING"
                    :commons="countryData"
                    :set-as-primary-address="setAsPrimaryAddress"
                    :add-or-update-address="addOrUpdateAddress"
                    :address="shippingAddress"
                  />
                </b-modal>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-card class="h-100 position-relative">
            <div class="price-details mb-3 pb-2">
              <div class="price-title">
                Order Details ({{ currency }})
              </div>
              <ul class="list-unstyled mb-0">
                <li class="price-detail">
                  <div class="detail-title">
                    Total Value
                  </div>
                  <div class="detail-amt">
                    {{ formatCurrency(totalMRP, currency) }}
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    Total Units
                  </div>
                  <div class="detail-amt">
                    {{ formatNumber(totalUnits) }}
                  </div>
                </li>
                <li
                  v-if="discountInCredit > 0"
                  class="price-detail"
                >
                  <div
                    class="
                      w-100
                      d-flex
                      justify-content-between
                      align-item-center
                    "
                  >
                    <div class="w-100">
                      <p class="price-title">
                        Available Credit Points
                        <span
                          v-if="!showCreditInput"
                          class="color-blue cursor-pointer"
                          @click="showCreditInput = true"
                        >
                          <span v-if="creditsUsed > 0"> Edit </span>
                          <span v-else> Use credit points </span>
                        </span>
                      </p>
                      <p class="detail-title mb-0">
                        {{ remainingCredits }} credits available
                      </p>
                    </div>
                    <div
                      v-if="showCreditInput"
                      class="d-flex align-items-center justify-content-end"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Credit Used"
                        vid="name"
                        :rules="`between:0,${getMaxCreditEligible}`"
                        class="d-flex"
                      >
                        <b-form-input
                          id="credit-used"
                          v-model="creditsUsed"
                          type="number"
                          name="credit-used"
                          class="credit-used-input"
                          :class="errors.length && 'danger-input'"
                        />
                        <b-tooltip
                          v-if="!!errors.length"
                          variant="danger"
                          target="credit-used"
                          placement="bottom"
                          custom-class="credits-tooltip"
                        >
                          Please enter a value below total order amount and
                          available credit points amount.
                        </b-tooltip>
                        <b-button
                          variant="info ml-50"
                          :disabled="!!errors.length"
                          @click="applyCredits"
                        >
                          Apply
                        </b-button>
                      </validation-provider>
                    </div>
                    <div
                      v-else
                      class="
                        d-flex
                        align-items-center
                        justify-content-end
                        text-nowrap
                      "
                    >
                      -{{ formatCurrency(appliedCredits, currency) }}
                    </div>
                  </div>
                </li>
              </ul>
              <hr class="mt-0">
              <ul class="list-unstyled mb-0">
                <li class="price-detail">
                  <div class="total-price">
                    Total
                  </div>
                  <div class="total-price">
                    {{ formatCurrency(total, currency) }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="position-bottom mb-1">
              <div class="my-50 mx-2">
                <div class="d-flex">
                  <b-form-checkbox
                    v-model="acceptTerms"
                    class="terms-checkbox"
                  >
                    I agree to the
                  </b-form-checkbox>
                  <span @click="$bvModal.show('cart-terms')">
                    <u class="color-actions-text-grey cursor-pointer">
                      terms and conditions
                    </u>
                  </span>
                </div>
                <small
                  v-if="isTermsUnChecked && !acceptTerms"
                  class="text-danger"
                >
                  Please accept the Terms and Conditions
                </small>
              </div>
              <div class="text-center my-1 mx-2">
                <b-button
                  variant="secondary-black"
                  block
                  :disabled="
                    isDisabledPlaceOrder || isDirectRetailerNotSelected
                  "
                  @click="placeOrder(false)"
                >
                  <span v-if="!isPlacingOrder"> Place Order </span>
                  <b-spinner
                    v-else
                    small
                  />
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!-- Products List -->
          <div class="mt-2">
            <cart-products
              ref="cartProducts"
              :is-popup="isPopup"
              :modal-id="modalId"
              :retailer-id="retailerId"
              :cart-products="cartProducts"
              @remove-product-selected-from-brand="
                removeProductSelectedFromBrand
              "
              @update-total="updateCartTotal"
              @update-total-units="updateCartTotalUnits"
              @has-cart-product="updateListView"
              @update-notes="
                (note) => {
                  notes = note;
                }
              "
            />
          </div>
        </b-col>
      </b-row>
      <cart-terms @update-terms="updateTerms" />
      <!-- Cart import , export and clear cart modals -->
      <cart-modals
        :clear-cart="clearCart"
        :is-popup="isPopup"
        :retailer-id="retailerId"
        :on-cart-import-success="onCartImportSuccess"
        :on-cart-export-success="onCartExportSuccess"
        :place-order="placeOrder"
        :cart-products="$refs.cartProducts"
        :on-click-export-cart="onClickExportCart"
      />
      <cart-export-modal
        :retailer-id="isPopup ? retailerId : null"
        @on-cart-export-success="onSuccessCartExport"
      />
    </div>
    <b-modal
      id="modal-order-fail"
      ref="orderFail"
      size="md"
      button-size="md"
      title="Order Creation Failed"
      hide-footer
      centered
    >
      <order-validation-message
        v-if="profileData"
        :profile-data="profileData"
      />
      <b-button
        class="my-1"
        variant="primary"
        block
        :to="{ name: 'profile' }"
      >
        Edit Profile
      </b-button>
    </b-modal>
    <tool-kit :toolkit-page="TOOLKIT_PAGE.CHECKOUT" />
  </div>
</template>

<script>
import ExportIcon from '@/@core/assets/svg-components/ExportIcon.vue'
import ImportIcon from '@/@core/assets/svg-components/ImportIcon.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'
import {
  apiToastError,
  apiToastSuccess,
  apiToastWarning,
} from '@/@core/utils/toast'
import { getProperProfileData } from '@/common-utils'
import constants, {
  CART_EXPORT_MODAL_ID,
  TOOLKIT_PAGE,
  VUE_FLATPICKR_MONTH_CONFIG,
} from '@/constants'
import store from '@/store'
import { FETCH_PROFILE } from '@/store/modules/auth.module'
import { FETCH_BRAND_DETAIL } from '@/store/modules/brand.module'
import { GET_COMMON_BASE, GET_COUNTRIES } from '@/store/modules/common.module'
import { CREATE_ORDER } from '@/store/modules/order.module'
import {
  ADD_PRODUCT_TO_CART,
  CLEAR_CART,
  UPDATE_CART_ITEMS_COUNT,
} from '@/store/modules/shop.module'
import { formatNumber, formatObject } from '@core/utils/utils'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BModal,
  BRow,
  BSpinner,
  BTooltip,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'
import {
  LOAD_CARTS,
  UPDATE_CART_ERRORS,
} from '@/store/modules/checkout-v2.module'
import NotificationMixin from '@/views/mixins/NotificationMixin.vue'
import UserRoleMixin from '../UserRoleMixin.vue'
import AddressDetail from '../account-setting/multiple-address/AddressDetail.vue'
import AddressList from '../account-setting/multiple-address/AddressList.vue'
import AddressMixinVue from '../account-setting/multiple-address/AddressMixin.vue'
import CartModals from './CartModals.vue'
import CartProducts from './CartProducts.vue'
import CartTerms from './CartTerms.vue'
import OrderValidationMessage from './OrderValidationMessage.vue'
import CartExportModal from './CartExportModal.vue'

const { formatCurrency, formattedDate } = utils

const { KP_MONTH_FORMAT } = c

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BRow,
    BCol,
    BModal,
    BFormInput,
    ValidationProvider,
    BTooltip,
    BFormCheckbox,
    BSpinner,
    // SFC
    OrderValidationMessage,
    // Address components
    AddressList,
    AddressDetail,
    ExportIcon,
    ImportIcon,
    CartTerms,
    CartProducts,
    CartModals,
    FeatherIcon,
    BFormTextarea,
    CartExportModal,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixin, AddressMixinVue, NotificationMixin],
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    cartProducts: {
      type: Array,
      default: () => [],
    },
    retailerId: {
      type: String,
      default: null,
    },
    removeProduct: {
      type: Function,
      default: () => {},
    },
    brandNotes: {
      type: Object,
      default: () => {},
    },
    getCartProductsByBrand: {
      type: Function,
      default: () => {},
    },
    modalId: {
      type: String,
      default: '',
    },
  },
  data() {
    const IMMEDIATE = 'Immediate'
    const CHOOSE_DATE = 'Choose Date'
    return {
      totalMRP: null,
      totalUnits: null,
      total: null,
      currency: null,
      showTotal: false,
      showPickUpDate: false,
      requestedDeliveryDate: null,
      orderId: null,
      selectedOption: IMMEDIATE,
      profileData: null,
      getProperProfileData,
      isLoaded: false,
      creditsUsed: 0,
      remainingCredits: 0,
      showCreditInput: false,
      TOOLKIT_PAGE,
      discountInCredit: 0,
      appliedCredits: 0,
      acceptTerms: false,
      isTermsUnChecked: false,
      isDisabledPlaceOrder: false,
      isPlacingOrder: false,
      commons: {},
      countryData: null,
      formatNumber,
      formattedDate,
      formatCurrency,
      IMMEDIATE,
      CHOOSE_DATE,
      isEditNotes: false,
      notes: '',
      isSavingNotes: false,
      addresses: [],
      VUE_FLATPICKR_MONTH_CONFIG,
      KP_MONTH_FORMAT,
    }
  },
  computed: {
    getMaxCreditEligible() {
      return this.totalMRP - 1 > this.discountInCredit
        ? this.discountInCredit
        : Number(this.totalMRP) - 1
    },
    isDirectRetailerNotSelected() {
      return this.isPopup && !this.retailerId
    },
    collectionName() {
      return this.$refs.cartProducts?.collectionName
    },
    collectionId() {
      return this.$refs.cartProducts?.collectionId
    },
    ...mapState({
      cartItemsCount: state => state.shop.cartItemsCount,
    }),
  },
  watch: {
    creditsUsed() {
      this.total = this.totalMRP - this.creditsUsed
      this.remainingCredits = this.discountInCredit - this.creditsUsed
    },
    acceptTerms() {
      if (!this.acceptTerms) {
        this.isTermsUnChecked = false
      }
    },
    notes() {
      if (this.isPopup) {
        const notesData = {
          notes: this.notes,
          isEditNotes: this.isEditNotes,
        }
        this.$emit('update-notes', notesData)
      }
    },
    shouldAddNotes() {
      return this.isBrand && this.isPopup && this.retailerId
    },
  },
  mounted() {
    this.loadCountries()
    this.getProfileData()
    if (this.isPopup) {
      this.notes = this.brandNotes?.notes || ''
      this.isEditNotes = this.brandNotes?.isEditNotes || false
    }
  },
  emits: ['remove-product-selected-from-brand', 'update-notes'],
  methods: {
    onSuccessCartExport() {
      this.onCartExportSuccess()
      this.notifyDownloadsNavMenu()
    },
    loadCountries() {
      this.$store
        .dispatch(GET_COUNTRIES)
        .then(res => {
          this.countryData = res?.data?.data
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    async onClickExportCart() {
      if (this.isDirectRetailerNotSelected) {
        apiToastWarning('Please select a retailer.')
        return
      }
      try {
        await this.saveCart(true)
      } catch (e) {
        return
      }

      this.$bvModal.show(CART_EXPORT_MODAL_ID)
    },
    removeProductSelectedFromBrand(product) {
      this.$emit('remove-product-selected-from-brand', product)
    },
    gotoCollection() {
      const [product] = this.$refs.cartProducts.products
      analytics.track(
        constants.TRACKS.ACTIONS.BACK_TO_COLLECTION,
        formatObject(product),
      )
    },
    updateListView(showTotal) {
      this.discountInCredit = this.$refs.cartProducts?.discountInCredit || 0
      this.remainingCredits = this.discountInCredit - this.appliedCredits
      this.creditsUsed = this.appliedCredits
      this.showTotal = !!showTotal
      this.isLoaded = true
    },
    updateCartTotal(totalMRP, currency) {
      // total price of one product = total values * quantity * wholesale Price
      this.totalMRP = Number(totalMRP).toFixed(2)
      this.total = Number(totalMRP).toFixed(2) - Number(this.creditsUsed).toFixed(2)
      if (currency) this.currency = currency
    },
    updateCartTotalUnits(totalUnits) {
      this.totalUnits = totalUnits
    },
    updateIsDisablePlaceOrder(disable = false) {
      this.isDisabledPlaceOrder = disable
    },
    updateIsPlacingOrder(isPlacing = false) {
      this.updateIsDisablePlaceOrder(isPlacing)
      this.isPlacingOrder = isPlacing
    },
    showBuyingSummary() {
      if (this.$refs.cartProducts.$refs.buyingsummary) {
        this.$refs.cartProducts.$refs.buyingsummary.show()
      }
    },
    async saveCart(shouldSaveCart = false) {
      const notesPayload = {}
      if (this.shouldAddNotes) {
        notesPayload.notes = this.notes
        notesPayload.retailerId = this.retailerId
      }
      return await this.$refs.cartProducts.saveCartProducts(
        notesPayload,
        shouldSaveCart,
      )
    },
    trackPlaceOrder() {
      const zeroValueProducts = this.getZeroValueProducts()
      const noOfProductsPlacedToOrder = this.$refs.cartProducts.products.length - zeroValueProducts.length

      if (this.isRetailer) {
        analytics.track(
          constants.TRACKS.ACTIONS.RETAILER_PLACES_ORDER,
          formatObject({
            collectionName: this.collectionName,
            noOfProductsPlacedToOrder,
            noOfProductsLeft: zeroValueProducts.length,
          }),
        )
      }
    },

    getZeroValueProducts() {
      const zeroValueProducts = []
      const cartProducts = this.$refs.cartProducts.products

      cartProducts.forEach(cartProduct => {
        if (!cartProduct.isCarton && !this.hasValueInItems(cartProduct.items)) {
          zeroValueProducts.push(cartProduct)
        }
      })

      return zeroValueProducts
    },

    hasValueInItems(items) {
      return items.some(item => item.value)
    },

    async placeOrder(shouldClearCart = false) {
      if (shouldClearCart) {
        await this.clearCart(this.retailerId)
      }

      this.updateIsPlacingOrder(true)

      if (!this.validateTerms()) return

      try {
        await this.saveCart()
        this.trackPlaceOrder()

        const data = this.buildOrderData()
        const res = await store.dispatch(CREATE_ORDER, data)

        this.handleOrderSuccess(res)
      } catch (err) {
        this.handleOrderError(err, shouldClearCart)
      }
    },

    validateTerms() {
      if (!this.acceptTerms) {
        this.isTermsUnChecked = true
        this.updateIsPlacingOrder(false)
        return false
      }
      return true
    },

    buildOrderData() {
      const data = {}

      if (this.isPopup && this.retailerId) {
        data.retailerId = this.retailerId
      }
      if (this.appliedCredits > 0) {
        data.discountInCredit = Number(this.appliedCredits)
      }

      return data
    },

    handleOrderSuccess(res) {
      store.commit(UPDATE_CART_ITEMS_COUNT, 0)
      apiToastSuccess(res.data.message)

      const { orderId, orderSeqId } = res.data.data
      this.orderId = orderId

      this.$root.$orderSuccessPopupContext.value = {
        orderIds: [{ orderSeqId }],
      }

      this.updateIsPlacingOrder(false)
      this.trackOrderAnalytics(res.data.data)
      this.routeToOrder()
    },

    handleOrderError(err, shouldClearCart) {
      this.updateIsPlacingOrder(false)

      if (this.isPopup && shouldClearCart) {
        this.clearCart(this.retailerId)
      }

      if (err?.response?.data?.data?.shouldDisplayPopUp) {
        this.$bvModal.show('modal-order-fail')
      } else {
        store.commit(UPDATE_CART_ERRORS)
        apiToastWarning(err)
      }
    },

    trackOrderAnalytics(data) {
      const action = this.isRetailer
        ? constants.TRACKS.ACTIONS.PLACE_ORDER
        : constants.TRACKS.ACTIONS.BRAND_CLICKS_ON_PLACE_ORDER

      analytics.track(action, formatObject(data))
    },

    routeToOrder() {
      this.$router.push({
        name: 'order/detail',
        params: { orderId: this.orderId },
      })
    },

    async getProfileData() {
      const profile = this.isPopup && this.retailerId
          ? await this.fetchBrandProfile()
          : await this.fetchUserProfile()

      this.profileData = getProperProfileData(profile)
    },

    async fetchBrandProfile() {
      const res = await store.dispatch(FETCH_BRAND_DETAIL, this.retailerId)
      return res.data.data
    },

    async fetchUserProfile() {
      const res = await store.dispatch(FETCH_PROFILE)
      return res
    },

    async getUserProfile(isAddressDetail = false) {
      try {
        const profile = await this.fetchUserProfile()
        this.profileData = getProperProfileData(profile)
        this.addresses = profile?.addresses || []
        this.getBillingAndShippingAddress(this.addresses)

        this.commons = await this.$store.dispatch(GET_COMMON_BASE)

        if (isAddressDetail) {
          this.showAddressModal()
        }
      } catch (err) {
        apiToastWarning(err)
      }
    },

    showAddressModal() {
      this.$bvModal.show('modal-select')
      analytics.track(
        constants.TRACKS.ACTIONS.RETAILER_CHANGES_ADDRESS_IN_CART,
      )
    },
    async onCartImportSuccess() {
      if (this.isPopup) {
        await this.getCartProductsByBrand(this.retailerId)
      } else {
        await store.dispatch(LOAD_CARTS)
        this.isLoaded = false
      }
    },
    async onCartExportSuccess() {
      if (this.isPopup) {
        await this.clearCart(this.retailerId)
      }
    },
    applyCredits() {
      if (this.creditsUsed > 0) {
        apiToastSuccess(
          'Your credit points were applied to the order successfully.',
        )
      }
      this.appliedCredits = this.creditsUsed
      this.showCreditInput = false
    },
    async clearCart(retailerId = '') {
      const payload = {}
      if (retailerId) {
        payload.retailerId = this.retailerId
      }
      analytics.track(
        constants.TRACKS.ACTIONS.RETAILER_CLEARS_CART,
        formatObject({
          noOfProductsInCart: this.cartItemsCount,
          collectionName: this.collectionName,
        }),
      )
      await this.$store
        .dispatch(CLEAR_CART, { payload })
        .then(res => {
          if (!this.isPopup) {
            store.commit(UPDATE_CART_ITEMS_COUNT, 0)
            apiToastSuccess(res.data.message)
            store.dispatch(LOAD_CARTS)
          }
        })
        .catch(err => {
          apiToastWarning(err)
        })
      this.$bvModal.hide('clear-cart')
      this.$bvModal.hide('clear-retailer-cart')
    },
    updateTerms() {
      this.acceptTerms = true
    },

    async onUpdateNotes() {
      if (this.notes) {
        const payload = {
          notes: this.notes,
        }
        if (this.isPopup && this.retailerId) {
          payload.retailerId = this.retailerId
        }
        this.isSavingNotes = true
        this.$store
          .dispatch(ADD_PRODUCT_TO_CART, { payload })
          .then(res => {
            apiToastSuccess(res.data.message || 'Notes updated successfully')
          })
          .catch(err => {
            apiToastWarning(err)
          })
          .finally(() => {
            this.isSavingNotes = false
            this.isEditNotes = false
          })
      } else {
        this.isEditNotes = false
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.checkout-options {
  margin-top: 54px;
}
.shipping-address-btn-margin {
  margin-bottom: 7.5rem;
}
</style>
